<template>
    {{nameObj.name}}
</template>
<script>
import { reactive } from '@vue/reactivity'
// import { ref } from 'vue'
export default {
  // create 实例完全初始化之前创建
  setup (props, context) {
    // let name = 'dell'
    // setTimeout(() => {
    //   name = 'hello'
    // }, 2000)
    // proxy, 'dell'变成proxy({value: 'dell'})这样一个响应式引用
    // const name = ref('dell')
    // setTimeout(() => {
    //   name.value = 'hello'
    // }, 2000)
    const nameObj = reactive({ name: 'dell' })
    setTimeout(() => {
      nameObj.name = 'hello'
    }, 2000)
    return { nameObj }
  }
}
</script>
