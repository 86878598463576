<template>
  <div class="fecth-nav">
    <div :class="{'fecth-nav-item': true, 'active': currentTab === 0}" @click="switchNav(0)">
      <span>送货上门</span>
      <img src="../../images/navtab_01.png" />
    </div>
    <div :class="{'fecth-nav-item': true, 'active': currentTab === 1}" @click="switchNav(1)">
      <span>站点自提</span>
      <img src="../../images/navtab_02.png" />
    </div>
  </div>
  <div class="address-wrap" v-if="currentTab === 0 && originatorId === '-1'">
    <router-link to="/address">
      <img class="address-icon-l" src="../../images/ico_location5.png" />
      <div class="deliver-address">
        <p v-if="addressModel.empty===true|| !addressModel.id">请选择收货地址</p>
        <div v-else>
          <p>{{addressModel.consignee}} {{addressModel.tel}}</p>
          <p>{{addressModel.provinceName+addressModel.cityName+addressModel.districtName+addressModel.address}}</p>
        </div>
      </div>
      <img class="address-icon-r" src="../../images/right_arrow.png" />
    </router-link>
  </div>
  <div class="address-wrap" v-if="currentTab === 0 && originatorId !== '-1'">
      <img class="address-icon-l" src="../../images/ico_location5.png" />
      <div class="deliver-address">
        <div>
          <p>{{originAddress.address}}</p>
        </div>
      </div>
  </div>
  <div class="custom-info" v-if="currentTab === 0 && originatorId !== '-1' && groupType === '1'">
      <div class="custom-info-list">
        <p>
          <span>提货人</span>
          <input type="text" v-model="receiverName" placeholder="请输入姓名"/>
        </p>
      </div>
      <div class="custom-info-list">
        <p>
          <span>手机号</span>
          <input type="text" v-model="receiverMobile" placeholder="请输入手机号"/>
        </p>
      </div>
    </div>
  <div v-if="currentTab === 1">
    <div class="address-wrap" v-if="originatorId !== '-1'">
      <img class="address-icon-l" src="../../images/ico_location5.png" />
      <div class="deliver-address">
        <p>自提点：</p>
        <p> {{originAddress.address}}</p>
      </div>
    </div>
    <div class="address-wrap" v-if="originatorId === '-1'">
      <router-link to="/picksite">
        <img class="address-icon-l" src="../../images/ico_location5.png" />
        <div class="deliver-address">
          <p>自提点：{{pickUpModel.deliveryName}}</p>
          <p> {{pickUpModel.deliveryAddress}}</p>
        </div>
        <img class="address-icon-r" src="../../images/right_arrow.png" />
      </router-link>
    </div>
    <div class="custom-info">
      <div class="custom-info-list">
        <p>
          <span>提货人</span>
          <input type="text" v-model="receiverName" placeholder="请输入姓名"/>
        </p>
      </div>
      <div class="custom-info-list">
        <p>
          <span>手机号</span>
          <input type="text" v-model="receiverMobile" placeholder="请输入手机号"/>
        </p>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="mygroup-list">
      <div class="mygroup-title">
        <img class="l" src="../../images/groupS.png" />
        <span class="l bold">{{cartList.shopName}}</span>
        <div class="clear"></div>
      </div>
      <div class="mygroup-info">
        <div class="mygroup-img">
          <img :src="cartList.photoUrl" />
        </div>
        <div class="mygroup-info-r">
          <h4>{{cartList.goodsName}}</h4>
          <div class="mygroup-info-list">
            <span class="price-new">¥{{cartList.shopPrice}}/{{cartList.unit}}</span>
          </div>
        </div>
        <span class="order-num">×{{cartList.goodsNum}}</span>
        <div class="clear"></div>
      </div>
      <div class="send_price">
        <label>配送费</label>
        <span>免费包邮</span>
        <div class="clear"></div>
      </div>
      <div class="order-info">
        <p>
          共<span>1</span>件商品<span></span
          ><span></span>合计
          <span>¥{{cartList.allPrice}}</span>
          (含运费¥0)
        </p>
      </div>
    </div>
  </div>
  <div class="fixed-confrim">
    <div class="order-info">
      <p>
        共<span>1</span>件商品<span></span
        ><span></span>总金额
          <span>¥{{cartList.allPrice}}</span>
      </p>
    </div>
    <button @click="confirmOrder" class="confirm-btn2" :disabled="isDisable">提交订单</button>
  </div>
</template>
<script>
import qs from 'qs'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { toRefs, onMounted, reactive } from 'vue'
import { post, ajaxParam, config } from '../../units/request.js'
import { Toast } from 'vant'
export default {
  name: 'Order',
  components: { },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { cartList, pickUpModel, originAddress, addressModel, orderRouteParam } = toRefs(store.state)
    const data = reactive({
      groupType: '',
      originatorId: '-1',
      ispri: false,
      addressId: '',
      currentTab: 0,
      dispatchType: 1,
      receiverName: '',
      receiverMobile: '',
      // abcPayDiscount: 0,
      isDisable: false
    })
    data.groupType = orderRouteParam.value.groupType
    data.originatorId = orderRouteParam.value.originatorId
    data.dispatchType = orderRouteParam.value.dispatchType
    const getDefaultAddress = async () => {
      const param = {
        userId: localStorage.userId
      }
      ajaxParam(param)
      const res = await post(
        'v1/userAddress/getDefaultAddress.json', qs.stringify(param)
      )
      if (res.data.code === '200') {
        const entity = res.data.entity
        if (entity) {
          store.commit('selectAddresssSend', entity)
          data.addressId = addressModel.value.id
          data.receiverName = addressModel.value.consignee || ''
          data.receiverMobile = addressModel.value.tel || ''
        }
      }
    }
    // addressModel 为空的时候获取默认地址 2021-08-12
    if (addressModel.value.empty) {
      getDefaultAddress()
    }
    onMounted(() => {
      const params = route.params.type
      if (params === 'picksite') {
        data.currentTab = 1
        getshopPrice()
      }
      if (data.dispatchType === '0') {
        data.currentTab = 1
      }
      if (data.dispatchType === '1') {
        data.currentTab = 0
      }
      if (data.originatorId !== '-1' && data.groupType === '1') {
        // 私域
        if (data.dispatchType === 1) {
          data.addressId = originAddress.value.address_id
          data.currentTab = 0
        }
        if (data.dispatchType === 0) {
          data.addressId = originAddress.value.self_delivery_id
          data.currentTab = 1
        }
        getshopPrice()
      } else {
        // 普通
        if (data.currentTab === 1) {
          data.addressId = pickUpModel.value.id
          data.dispatchType = 0
        }
        if (data.currentTab === 0) {
          // getDefaultAddress()
          data.addressId = addressModel.value.id
          data.receiverName = addressModel.value.consignee || ''
          data.receiverMobile = addressModel.value.tel || ''
          data.dispatchType = 1
        }
      }
    })
    const confirmOrder = async () => {
      data.isDisable = true
      if (data.currentTab === 0 && data.addressId === undefined) {
        Toast('请选择送货地址')
        data.isDisable = false
        return false
      }
      if ((pickUpModel.value.id === '' || pickUpModel.value.id === undefined) && (originAddress.value.address_id === '' || originAddress.value.address_id === undefined) && data.currentTab === 1) {
        Toast('请选择自提点')
        data.isDisable = false
        return
      }
      if (data.currentTab === 1 && !data.receiverName) {
        Toast('请输入收货人姓名')
        data.isDisable = false
        return
      }
      if (data.currentTab === 1 && !data.receiverMobile) {
        Toast('请输入收货人手机号')
        data.isDisable = false
        return
      }
      if (data.originatorId !== '-1') {
        if (!data.receiverName) {
          Toast('请输入收货人姓名')
          data.isDisable = false
          return
        }
        if (!data.receiverMobile) {
          Toast('请输入收货人手机号')
          data.isDisable = false
          return
        }
      }
      var regNum = new RegExp('^1[3-9][0-9]{9}$')
      if (!regNum.test(data.receiverMobile)) {
        Toast('请输入正确的手机号')
        data.isDisable = false
        return
      }
      const shopOrder = {
        orderAmount: cartList.value.allPrice,
        shippingFee: 0,
        depositAmount: cartList.value.depositAmount,
        dispatchType: data.dispatchType,
        addressId: data.addressId,
        // addressId: '6',
        goodAmount: cartList.value.goodsNum,
        shopId: cartList.value.shopId,
        goodsList: [{
          groupPurchaseId: cartList.value.groupId,
          goodsNumber: cartList.value.goodsNum
        }]
      }
      const param = {
        makeGroupId: cartList.value.makeGroupId,
        userId: localStorage.userId,
        customerType: 0,
        isJson: 1,
        addressId: data.addressId,
        // addressId: '6',
        payType: 9,
        terminalType: 7, // 终端来源(0:Andiod,1:Android-Pad,2:IOS,3:H5,4小程序)
        shopOrder: [shopOrder],
        receiverName: data.receiverName || '',
        receiverMobile: data.receiverMobile || ''
      }
      ajaxParam(param)
      setTimeout(() => {
        data.isDisable = false
      }, 3000)
      const res = await post('v1/groupPurchase/saveClientOrder.json', param)
      if (res.data.code === '500') {
        Toast('服务器端错误')
        return false
      }
      if (res.status === 200) {
        const paySn = res.data.orderPaySn
        router.replace({
          path: '/paytype',
          query: {
            paySn: paySn
          }
        })
      }
    }
    const switchNav = async (currentTab) => {
      if (data.currentTab === currentTab || data.originatorId !== '-1') {
        return false
      }
      data.currentTab = currentTab
      if (data.currentTab === 1) {
        data.addressId = pickUpModel.value.id
        data.dispatchType = 0
        getshopPrice()
      }
      if (data.currentTab === 0) {
        data.addressId = addressModel.value.id
        data.dispatchType = 1
        data.receiverName = addressModel.value.consignee
        data.receiverMobile = addressModel.value.tel
      }
    }
    const getshopPrice = async () => {
      const param = {
        shopIds: config.shopId,
        customerUserId: localStorage.userId || ''
      }
      ajaxParam(param)
      const res = await post('v1/userShop/getShopsPrice.json', qs.stringify(param))
      if (res.data.code === '200') {
        // data.abcPayDiscount = res.data.list[0].abcPayDiscount
        data.receiverMobile = res.data.receiverMobile
        data.receiverName = res.data.receiverName
      }
    }
    // const finalPrice = computed(() => {
    //   const rPrice = cartList.value.allPrice * data.abcPayDiscount / 100
    //   return rPrice.toFixed(2)
    // })
    const { originatorId, groupType, ispri, currentTab, receiverName, receiverMobile, isDisable } = toRefs(data)
    return { isDisable, confirmOrder, currentTab, cartList, ispri, pickUpModel, originAddress, originatorId, groupType, switchNav, addressModel, receiverName, receiverMobile }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
.fecth-nav {
  height: 0.4rem;
  border-radius: 10px 10px 0 0;
  background: #fff;
  line-height: 0.4rem;
  font-size: 0.16rem;
  position: relative;
}
.fecth-nav-item {
  width: 50%;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 2;
}
.fecth-nav-item span {
  position: absolute;
  width: 100%;
  height: 0.4rem;
  text-align: center;
}
.fecth-nav-item img {
  width: 100%;
  height: 0.4rem;
}
.fecth-nav-item:nth-of-type(1) {
  left: 0;
  border-radius: 10px 0 0 10px;
}
.fecth-nav-item:nth-of-type(1) img {
  border-radius: 10px 0 0 10px;
}
.fecth-nav-item:nth-of-type(1) span {
  left: 0;
}
.fecth-nav-item:nth-of-type(2) {
  right: 0;
  border-radius: 0 10px 0 0;
}
.fecth-nav-item:nth-of-type(2) img {
  border-radius: 0 10px 0 0;
}
.fecth-nav-item:nth-of-type(2) span {
  right: 0;
}
.fecth-nav-item.active {
  background: #fff;
  color: #2ac560;
}
.fecth-nav-item.active img {
  display: none;
}
.fecth-nav-item:nth-of-type(2).active {
  z-index: 1;
}
.address-wrap {
  background: #fff;
  position: relative;
}
.address-icon-l {
  display: block;
  width: 0.24rem;
  height: 0.24rem;
  position: absolute;
  left: 0.15rem;
  top: 50%;
  margin-top: -0.12rem;
}
.address-icon-r {
  display: block;
  width: 0.12rem;
  height: 0.21rem;
  position: absolute;
  right: 0.15rem;
  top: 50%;
  margin-top: -0.11rem;
}
.deliver-address {
  padding: .1rem 0.3rem .1rem 0.6rem;
}
.deliver-address p {
  line-height: 0.3rem;
}
.content {
  margin-top: 0.1rem;
  background: #fff;
}
.mygroup-list {
  background: #fff;
  margin-bottom: 0.2rem;
  padding: 0 0.2rem 0.2rem;
  position: relative;
}
.mygroup-title {
  display: flex;
  align-items: center;
  height: 0.48rem;
  line-height: 0.48rem;
  border-bottom: 1px solid #f2f2f2;
  font-size: 0.16rem;
}
.mygroup-title img {
  width: 0.24rem;
  height: 0.24rem;
  margin: 0 0.1rem 0 0;
}
.mygroup-info {
  margin: 0.2rem 0;
  position: relative;
}
.mygroup-img {
  float: left;
  width: 1rem;
  height: 1rem;
  margin-right: 0.15rem;
}
.mygroup-img img {
  width: 100%;
  height: 100%;
}
.mygroup-info-r {
  font-size: 0.18rem;
  overflow: hidden;
}
.mygroup-info-r h4 {
  margin-top: 0.05rem;
  font-size: 0.16rem;
}
.mygroup-info-list {
  margin-top: 0.2rem;
  font-size: 0.15rem;
}
.mygroup-bom {
  height: 0.4rem;
  padding-left: 0.1rem;
  line-height: 0.4rem;
  color: #2ac560;
}
.mygroup-bom img {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  float: left;
  margin-left: -0.1rem;
}
.mygroup-num {
  margin-left: 0.05rem;
  font-size: 0.18rem;
}
.send_price {
  height: 0.56rem;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  line-height: 0.56rem;
}
.send_price label {
  float: left;
}
.send_price span {
  float: right;
  color: #999;
}
.order-info {
  height: 0.48rem;
  border-bottom: 1px solid #f3f3f3;
  line-height: 0.56rem;
  text-align: right;
}
.order-info span {
  padding: 0 0.05rem;
  color: $red-font;
}
.order-num {
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.25rem;
  font-size: 0.18rem;
}
.fixed-confrim {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.4rem;
  background: #fff;
  z-index: 99;
}
.fixed-confrim .order-info {
  float: left;
  margin-left: 0.2rem;
  border-bottom: none;
  height: 0.4rem;
  line-height: 0.4rem;
}
.confirm-btn2 {
  float: right;
  width: 1rem;
  height: 0.4rem;
  background: #0caa38;
  text-align: center;
  line-height: 0.4rem;
  color: #fff;
  font-size: 0.16rem;
  border:none
}
.custom-info{
  background:#fff;
  padding: .1rem .2rem 0;
}
.custom-info-list{
  border-top: 1px solid #f2f2f2;
  padding-left: .4rem;
  height: .36rem;
  line-height: .36rem;
}
.custom-info p{}
.custom-info p span{margin-right: .1rem}
.custom-info p input{border:none;  outline: none;}
.discount{color:red; text-align: right;}
</style>
