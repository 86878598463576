<template>
    <div class="content">
        <div class="order-status">
            <div class="order-status-l">
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==0">待确认</div>
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==1">待配货</div>
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==2">待买家收货</div>
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==3">待评价</div>
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==4">订单已被取消</div>
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==5">交易已成功</div>
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==6">待支付</div>
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==7">退款中</div>
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==8">已退款</div>
                <div class="order-status-txt" v-if="orderDetailModel.orderStatus==9">已配货,待发货</div>
                <div class="pay-status" v-if="orderDetailModel.payStatus==0">(未付款)</div>
                <div class="pay-status" v-if="orderDetailModel.payStatus==1">(付款中)</div>
                <div class="pay-status" v-if="orderDetailModel.payStatus==2">(已付款)</div>
                <div class="pay-type" v-if="orderDetailModel.payType==0">货到付款</div>
                <div class="pay-type" v-if="orderDetailModel.payType==1">微信支付</div>
                <div class="pay-type" v-if="orderDetailModel.payType==2">支付宝支付</div>
                <div class="pay-type" v-if="orderDetailModel.payType==3">银联支付</div>
                <div class="pay-type" v-if="orderDetailModel.payType==4">建行支付</div>
                <div class="pay-type" v-if="orderDetailModel.payType==8">余额支付</div>
            </div>
            <img class="order-status-r" src="../../images/bag.png"/>
        </div>
        <div class="order-user">
            <img src="../../images/ico_location5.png"/>
            <div class="order-user-info">
                <div>{{orderDetailModel.customerName}} {{orderDetailModel.mobile}}</div>
                <div>{{orderDetailModel.address}}</div>
            </div>
        </div>
        <div class="orderlist">
            <div class="order-shop">
                <div class="order-shop-l">
                    <img class="shop-icon" src="../../images/order_company@2x.png"/>
                    <span>{{orderDetailModel.shopName}}</span>
                    <img class="shop-arrow" src="../../images/right_arrow.png"/>
                </div>
            </div>
            <div class="goodslist" v-for="item in orderDetailModel.goodsList" :key="item.id">
                <img :src="item.photoUrl"/>
                <div class="goods-info">
                    <div class="goods-name">{{item.goodsName}}</div>
                    <div class="goods-spec">规格：{{item.spec}}</div>
                    <div class="goods-price">￥{{item.nowGoodsPrice}}</div>
                </div>
                <span class="goods-num">×{{item.nowGoodsNumber}}</span>
            </div>
            <div class="detail-cell">
                <span>运费</span>
                <span>￥{{orderDetailModel.shippingFee}}</span>
            </div>
            <div class="detail-cell">
                <span>实付款（含运费）</span>
                <span>￥{{orderDetailModel.orderAmount}}</span>
            </div>
        </div>
        <div class="order-info">
            <div class="order-info-list">
                <span class="order-list-item">订单编号</span>
                <span>{{orderDetailModel.orderSn}}</span>
            </div>
            <div class="order-info-list">
                <span class="order-list-item">创建时间</span>
                <span>{{orderDetailModel.addTime}}</span>
            </div>
            <div class="order-info-list">
                <span class="order-list-item">支付流水号</span>
                <span>{{orderDetailModel.paySn}}</span>
            </div>
            <div class="order-info-list">
                <span class="order-list-item">支付类型</span>
                <span v-if="orderDetailModel.paySnType==0">单笔订单支付</span>
                <span v-if="orderDetailModel.paySnType==1">批量订单支付</span>
            </div>
            <div class="order-info-list" v-for="item in orderDetailModel.action" :key="item.id">
                <span class="order-list-item">{{item.action}}</span>
                <span>{{item.addTime}}</span>
            </div>
        </div>
    </div>
    <div class="order-bom">
        <span class="order-btn" @click="cancalOrderClick" v-if="orderDetailModel.orderStatus==0||orderDetailModel.orderStatus==6">取消订单</span>
        <span class="order-btn order-green" @click="confirmOrderClick" v-if="orderDetailModel.orderStatus==2">确认收货</span>
        <span class="order-btn order-green" v-if="orderDetailModel.orderStatus==6" @click="$router.push({ path:'/paytype', query: {paySn:orderDetailModel.paySn,isGroup: 0,allAmount: orderDetailModel.orderAmount}})">付款</span>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
export default {
  data () {
    return {
      orderDetailModel: ''
    }
  },
  methods: {
    async getOrderDetailData () {
      const url = 'v1/orderInfo/client/details.json'
      const param = {
        orderId: this.orderId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.orderDetailModel = res.data.entity
    },
    // 取消订单
    cancalOrderClick () {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '是否取消当前订单？'
      }).then(() => {
        // confirm 取消订单
        this.changeOrderStatus(this.orderId, 4)
      })
    },
    // 确认收货
    confirmOrderClick () {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '是否确认收货？'
      }).then(() => {
        // confirm 确认收货
        this.changeOrderStatus(this.orderId, 3)
      })
    },
    // 改变订单状态
    async changeOrderStatus (orderId, status) {
      const url = 'v1/orderInfo/client/editStatus.json'
      const param = {
        userId: localStorage.userId,
        orderStatus: status,
        orderId: orderId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      this.getOrderDetailData()
    }
  },
  created () {
    this.orderId = this.$route.query.orderId
    this.getOrderDetailData()
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
.content{
    padding-bottom: .46rem;
}
.order-status{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .2rem;
    background:#fff;
    font-size: .13rem;
    .order-status-l{
        line-height: .24rem;
        color:$red-font;
        .pay-type{
            color:#666;
            font-size: .12rem;
        }
    }
    .order-status-r{
        width: 1.18rem;
        height: .99rem
    }
}
.order-user{
    display: flex;
    justify-content: space-between;
    padding: .1rem .2rem;
    background:#fff;
    align-items: center;
    border-top: 1px solid #f3f3f3;
    img{
        width: .2rem;
        height: .2rem;
        margin-right:.05rem;
    }
    .order-user-info{
        flex:1;
        line-height: .24rem;
        color:#666;
        font-size: .13rem;
    }
}
.orderlist{
    background:#fff;
    margin: .1rem 0;
    padding: .1rem .15rem;
    .order-shop{
        display: flex;
        justify-content: space-between;
        padding-bottom: .1rem;
        border-bottom: 1px solid #f3f3f3;
        .order-shop-l{
            display: flex;
            align-items: center;
            .shop-icon{
                width: .19rem;
                height: .19rem;
            }
            span{
                margin: 0 .08rem;
                font-size: .13rem;
            }
            .shop-arrow{
                width:.09rem;
                height: .1rem;
            }
        }
    }
    .goodslist{
        width: 100%;
        padding: .1rem 0;
        box-sizing: border-box;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        align-items: center;
        img{
            width:.7rem;
            height: .7rem;
            margin-right:.1rem;
        }
        .goods-info{
            flex:1;
            position: relative;
            height:.62rem;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            .goods-name{
                font-weight: bold;
            }
            .goods-spec{
                color:#666; font-size: .13rem;
            }
            .goods-price{
                color:#52da5f
            }
        }
    }
    .detail-cell{
        display: flex;
        justify-content: space-between;
        padding: .08rem 0 0;
        color:#666;
        font-size: .12rem;
    }
}
.order-info{
    background:#fff;
    padding:.1rem .15rem;
    .order-info-list{
        padding: .1rem 0 0;
        font-size: .13rem;
        .order-list-item{
            margin-right: .15rem;
            color:#666
        }
    }
}
.order-bom{
    position:fixed;
    left:0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #ebebeb;
    height: .46rem;
    background:#fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .order-btn{
        margin-right: .15rem;
        padding:.06rem .1rem;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        font-size: .12rem;
    }
    .order-green{
        background:#2ac560;
        border-color: #2ac560;
        color:#fff
    }
}
</style>
