<template>
    <div class="content">
        <div class="feedback">
            <textarea placeholder="请输入您的宝贵意见" v-model="text" @change="textListen($event)"></textarea>
        </div>
    </div>
    <div class="fixed-bom" @click="toSubmit">提 交</div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
export default {
  data () {
    return {
      text: '',
      feedType: 0
    }
  },
  methods: {
    textListen (e) {
      this.text = e.target.value
      console.log(this.text)
    },
    async toSubmit () {
      if (this.feedType === '1') {
        localStorage.message = this.text
        localStorage.isUpdateMessage = true
        this.$router.back()
        return
      }
      if (this.text.length === 0) {
        this.$toast('请输入您的意见！')
        return
      }
      var url = 'v1/suggest/submitInfo.json'
      var param = {
        userId: localStorage.userId,
        source: 'MINI',
        content: this.text
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      this.$dialog.confirm({
        title: '温馨提示',
        message: '您的意见我们已经收到!'
      }).then(() => {
        // confirm 返回
        this.$router.back()
      })
    }
  },
  created () {
    const messageModel = localStorage.getItem('messageModel') ? JSON.parse(localStorage.getItem('messageModel')) : {}

    if (messageModel.message !== undefined && messageModel.message !== '选填，填写您和卖家达成一致的要求') {
      this.text = localStorage.message
    }
    this.feedType = this.$route.params.feedType
  }
}
</script>
<style lang="scss" scoped>
.content{
    padding-bottom: .46rem;
}
.feedback{
    textarea{
        width: 100%;
        height: 1.2rem;
        padding: .1rem;
        box-sizing: border-box;
        border:none
    }
    textarea:focus{
        outline: none;
    }
}
</style>
