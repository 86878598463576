<template>
    <div class="container">
      <Search :isShowBtn="true" :searchKeyInput="searchKey" @cancelSearch="cancelSearch" @searchListen="searchListen"/>
      <div class="orderlist-wrap">
        <van-list v-if="orderList.length > 0"
              v-model:loading="loading"
              :finished="finished"
              :immediate-check = "false"
              finished-text="没有更多了"
              @load="pageLoad"
            >
             <OrderList v-for="item in orderList" :key="item.id" :orderItem="item" @updateOrder="updateOrder"/>
        </van-list>
        <ContentNo :contentTips="contentTips" v-if="orderList.length <=0"/>
      </div>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam, formatTime } from '../../units/request.js'
import Search from '../../components/Search.vue'
import OrderList from '../../components/OrderList.vue'
import ContentNo from '../../components/ContentNo.vue'
export default {
  data () {
    return {
      orderList: [],
      status: this.$route.params.status || '',
      searchKey: '',
      pageNum: 1,
      pageSize: 6,
      isLastPage: 0,
      finished: false,
      loading: false,
      contentTips: '暂无订单'
    }
  },
  components: {
    Search, OrderList, ContentNo
  },
  methods: {
    async getOrderList () {
      const url = 'v1/orderInfo/client/orderList.json'
      const param = {
        userId: localStorage.userId,
        status: this.status,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        searchKey: this.searchKey
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      // this.orderList = res.data.list
      const list = res.data.list
      list.forEach(item => {
        const myDate = new Date(item.addTime)
        item.addTime = formatTime(myDate)
      })
      this.orderList.push(...list)
      this.isLastPage = res.data.isLastPage
      if (this.isLastPage === 1) {
        this.finished = true
      }
      // 获取完数据把Loading状态改成false
      this.loading = false
    },
    // 分页
    pageLoad () {
      this.loading = true
      this.pageNum++
      this.getOrderList()
    },
    // 输入搜索
    searchListen (searchKeyInput) {
      this.searchKey = searchKeyInput
      this.finished = false
      this.loading = false
      this.pageNum = 1
      this.orderList = []
      this.getOrderList()
    },
    cancelSearch () {
      this.searchKey = ''
      this.pageNum = 1
      this.orderList = []
      this.getOrderList()
    },
    // 更新订单列表
    updateOrder () {
      this.finished = false
      this.loading = false
      this.pageNum = 1
      this.orderList = []
      this.getOrderList()
    }
  },
  created () {
    this.getOrderList()
  }
}
</script>

<style lang="scss" scoped>
.orderlist-wrap{padding-bottom: .1rem;}
</style>
