<template>
    <div class="confrim-order">
        <div class="fecth-nav">
            <div :class="{'fecth-nav-item': true, 'active': currentTab === 0}" @click="switchNav(0)">
                <span>送货上门</span>
                <img src="../../images/navtab_01.png" />
            </div>
            <div :class="{'fecth-nav-item': true, 'active': currentTab === 1}" @click="switchNav(1)">
                <span>站点自提</span>
                <img src="../../images/navtab_02.png" />
            </div>
        </div>
        <div class="address-wrap" v-if="currentTab === 0">
            <router-link to="/address">
            <img class="address-icon-l" src="../../images/ico_location5.png" />
            <div class="deliver-address">
                <div v-if="addressModel.empty===true || !addressModel.id"><p>请选择收货地址</p></div>
                <div v-else>
                    <p>{{addressModel.consignee}} {{addressModel.tel}}</p>
                    <p>{{addressModel.provinceName+addressModel.cityName+addressModel.districtName+addressModel.address}}</p>
                </div>
            </div>
            <img class="address-icon-r" src="../../images/right_arrow.png" />
            </router-link>
        </div>
        <div class="address-wrap" v-if="currentTab === 1">
            <router-link :to="{name: 'PickSite', query: {confirmType: 1}}">
                <img class="address-icon-l" src="../../images/ico_location5.png" />
                <div v-if="JSON.stringify(pickUpModel)!='{}'" class="deliver-address">
                    <p>自提点：{{pickUpModel.deliveryName}}</p>
                    <p> {{pickUpModel.deliveryAddress}}</p>
                </div>
                <div v-else class="deliver-address">
                    <p>请选择自提点地址</p>
                </div>
                <img class="address-icon-r" src="../../images/right_arrow.png" />
            </router-link>
        </div>
        <div class="orderlist" v-for="(item , index) in shoplist" :key="item.id">
            <div class="order-shop">
                <div class="order-shop-l">
                    <img class="shop-icon" src="../../images/order_company@2x.png"/>
                    <span>{{item.shopName}}</span>
                    <img class="shop-arrow" src="../../images/right_arrow.png"/>
                </div>
            </div>
            <div class="goodslist" v-for="itemSub in item.goodsList" :key="itemSub.id">
                <img :src="itemSub.photoUrl" />
                <div class="goods-info">
                    <div class="goods-name">{{itemSub.goodsName}}</div>
                    <div class="goods-spec">规格：{{itemSub.unit}}</div>
                    <div class="goods-price">￥{{itemSub.vipPrice?itemSub.vipPrice:itemSub.shopPrice}}</div>
                </div>
                <span class="goods-num">×{{itemSub.goodsNum}}</span>
            </div>
            <div class="detail-cell">
                <label>配送费</label>
                <span>￥{{sendPrice}}</span>
            </div>
<!--            <div class="detail-cell">-->
<!--                <label>购物券</label>-->
<!--                <span>￥0</span>-->
<!--            </div>-->
            <div class="detail-cell detail-coupon-cell" @click="todiscountCoupon(item.shopId,index)">
                <label>优惠活动</label>
                <span>{{item.couponsTitle || '无优惠'}}</span>
                <img class="shop-arrow" src="../../images/right_arrow.png"/>
            </div>
            <div class="detail-cell" @click="selectMessageClick(item)">
                <label>买家留言</label>
                <span>{{item.message || '选填，填写您和卖家达成一致的要求'}}</span>
            </div>
            <div class="detail-cell shop-amount">
                共<span> {{item.goodsList.length}} </span>件商品 合计<span>￥{{item.allAmount}}</span>
            </div>
        </div>
    </div>
    <div class="fix-bottom">
        <div class="fix-bottom-l">
            共<span>{{goodsNumber}}</span>件商品，合计<span>￥{{allAmount}}</span>
        </div>
        <div class="fix-bottom-r" @click="confirmOrder">提交订单</div>
    </div>
</template>
<script>
import { post, ajaxParam, config } from '../../units/request.js'
import qs from 'qs'
import router from '../../router'
export default {
  data () {
    return {
      currentTab: 0,
      allAmount: 0,
      sendPrice: 0,
      goodsNumber: 0,
      addressModel: {},
      shoplist: [],
      shopOrder: [],
      couponsList: [], // 优惠券列表
      checkCouponsUserIds: [], // 选中优惠券ID
      allFullCatMoney: 0, // 兼容代码使用
      couponsFlag: false // 是否合并
      // dispatchType 0站点自提 1送货上门 9都可以
    }
  },
  methods: {
    switchNav (currentTab) {
      this.currentTab = currentTab
    },
    // 获取默认地址
    async getDefaultAddress () {
      const url = 'v1/userAddress/getDefaultAddress.json'
      const param = {
        userId: localStorage.userId
      }
      ajaxParam(param)
      const res = await post(
        url, qs.stringify(param)
      )
      if (res.data.code === '200') {
        const entity = res.data.entity
        if (entity) {
          this.$store.commit('selectAddresssSend', entity)
          this.addressModel = entity
        }
      }
    },
    // 计算商品金额
    getAllAmount () {
      let allAmount = 0
      this.shoplist.forEach((item, index) => {
        this.goodsNumber += item.goodsList.length// 总共商品数量
        item.shopAmount = 0
        item.orderAmount = 0// 订单价格
        item.goodsAmount = 0// 商品价格
        item.shippingFee = this.sendPrice// 运费
        item.dispatchType = 0// 派送方式
        item.addressId = this.shopOrder.addressId// 自提点地址
        item.goodsList.forEach((itemSub, indexSub) => {
          allAmount += itemSub.goodsNum * (itemSub.vipPrice ? itemSub.vipPrice : itemSub.shopPrice)
          item.shopAmount += itemSub.goodsNum * (itemSub.vipPrice ? itemSub.vipPrice : itemSub.shopPrice)
          item.goodsAmount += itemSub.goodsNum * (itemSub.vipPrice ? itemSub.vipPrice : itemSub.shopPrice)
          itemSub.goodsNumber = itemSub.goodsNum
        })
        item.orderAmount = item.goodsAmount + item.shippingFee
        item.shopAmount = item.shopAmount + item.shippingFee
        item.allAmount = item.orderAmount
        item.tcGoodsMoneyCount = 0 // 兼容代码使用暂时无用 套餐商品总金额
        item.fullCatMoney = 0 // 兼容代码使用暂时无用 满减金额
        item.depositGoodsAmount = 0 // 兼容代码使用暂时无用 押金金额
        allAmount += item.shippingFee
        this.shopOrder.push(item)
      })
      this.allAmount = allAmount
      // 计算优惠券
      this.shopOrder.forEach((item, index) => {
        this.getCoupons(item)
      })
    },
    // 获取优惠券
    async getCoupons (order) {
      var url = 'v1/coupons/couponsList.json'
      var param = {
        userId: localStorage.userId,
        shopId: order.shopId,
        orderAmount: order.orderAmount,
        sendPrice: order.shippingFee
      }
      // 如果缓存中没有该优惠券 则重新获取
      var flag = false
      if (this.couponsList.length !== 0) {
        for (var j = 0; j < this.couponsList.length; j++) {
          var coupons = this.couponsList[j]
          if (coupons.shopId === order.shopId) {
            flag = true
          }
        }
      }
      if (flag === false) {
        ajaxParam(param)
        const res = await post(url, qs.stringify(param))
        res.data.list.forEach(item => {
          item.useFlag = 0 // 不可用
        })
        this.couponsList.push(res.data)
      }
      // 自动选择优惠券
      this.checkCoupons(order.shopId)
    },
    // 选择优惠券
    checkCoupons (shopId) {
      var orderList = this.shopOrder
      var checkCouponsUserIds = this.checkCouponsUserIds
      var couponsList = this.couponsList
      var allFullCatMoney = this.allFullCatMoney
      var allAmount = this.allAmount
      var couponsAmount = 0// 优惠券金额
      for (var j = 0; j < orderList.length; j++) {
        // 避免重复计算只计算当前店铺
        var order = orderList[j]
        if (order.shopId === shopId) {
          var goodsList = order.goodsList
          for (var i = 0; i < couponsList.length; i++) {
            var oneCoupons = couponsList[i]
            var checkedFlag = false
            if (order.shopId === oneCoupons.shopId) {
              order.couponsList = oneCoupons.list
              for (var k = 0; k < order.couponsList.length; k++) {
                var coupons = oneCoupons.list[k]
                if (coupons.status === 2) { // 商品优惠券
                  var goodsIds = coupons.goodsIds.split(',')
                  var couponsGoodsAmount = 0
                  var shoppingVoucherAmount = 0
                  for (var m = 0; m < goodsIds.length; m++) {
                    var gId = goodsIds[m]
                    for (var g = 0; g < goodsList.length; g++) {
                      if (goodsList[g].goodsId === gId) {
                        couponsGoodsAmount += goodsList[g].shopPrice * goodsList[g].goodsNum
                        if (goodsList[g].shoppingVoucherFlag === '1') {
                          shoppingVoucherAmount += goodsList[g].goodsNum * (goodsList[g].shoppingVoucherAmount || 0)
                        }
                      }
                    }
                  }
                  // 商品总金额 减去 购物券金额
                  if (this.data.orderShoppingVoucherFlag === '1') {
                    couponsGoodsAmount = couponsGoodsAmount - shoppingVoucherAmount
                  }
                  // 商品总金额 大于 优惠券最小订单金额
                  if (parseFloat(parseFloat(couponsGoodsAmount).toFixed(2) - parseFloat(coupons.orderAmount).toFixed(2)) >= 0) {
                    // 优惠券金额 大于 上一个可用优惠券金额
                    if (parseFloat(parseFloat(coupons.amount).toFixed(2) - parseFloat(couponsAmount).toFixed(2)) > 0) {
                      couponsAmount = coupons.amount
                      order.couponsUserId = coupons.couponsUserId
                      order.couponsAmount = coupons.amount
                      order.couponsTitle = coupons.title
                      order.allAmount = (order.allAmount - coupons.amount).toFixed(2)
                      order.fullCatMoney = order.fullCatMoney === '' ? coupons.amount : (coupons.amount + order.fullCatMoney)
                      allFullCatMoney = allFullCatMoney === '' ? coupons.amount : (coupons.amount + allFullCatMoney)
                      checkedFlag = true
                      checkCouponsUserIds.push(coupons.couponsUserId)
                      break
                    }
                  }
                }
                if (coupons.status === '1') {
                  // 店铺优惠券 无条件使用 有条件使用
                  if ((coupons.usedCondition === '0' && parseFloat(parseFloat(order.allAmount - order.tcGoodsMoneyCount - order.depositGoodsAmount).toFixed(2) - parseFloat(order.sendPrice || 0).toFixed(2) - parseFloat(coupons.orderAmount).toFixed(2)) >= 0) ||
                    (coupons.usedCondition === '1' && parseFloat(parseFloat(order.allAmount - order.tcGoodsMoneyCount - order.depositGoodsAmount).toFixed(2) - parseFloat(order.sendPrice || 0).toFixed(2) - parseFloat(coupons.amount).toFixed(2)) >= 0)) {
                    // 优惠券金额 大于 上一个可用优惠券金额
                    if (parseFloat(parseFloat(coupons.amount).toFixed(2) - parseFloat(couponsAmount).toFixed(2)) > 0) {
                      order.couponsUserId = coupons.couponsUserId
                      order.couponsAmount = coupons.amount
                      order.couponsTitle = coupons.title
                      order.allAmount = (order.allAmount - coupons.amount).toFixed(2)
                      order.fullCatMoney = order.fullCatMoney === '' ? coupons.amount : (coupons.amount + order.fullCatMoney)
                      allFullCatMoney = allFullCatMoney === '' ? coupons.amount : (coupons.amount + allFullCatMoney)
                      checkedFlag = true
                      checkCouponsUserIds.push(coupons.couponsUserId)
                      break
                    }
                  }
                }
                if (checkedFlag) {
                  break
                }
              }
            }
          }
        } else {

        }
      }
      allAmount = allAmount - allFullCatMoney
      this.allAmount = allAmount
      this.shopOrder = orderList
      this.couponsList = couponsList
      this.checkCouponsUserIds = checkCouponsUserIds
      // this.calcTotal()
      this.showCouponsTS(shopId)
    },
    // 提示展示优惠券
    showCouponsTS (shopId) {
      var orderList = this.shopOrder
      var checkCouponsUserIds = this.checkCouponsUserIds
      var couponsList = this.couponsList
      var checkFlag = false
      for (var j = 0; j < orderList.length; j++) {
        var order = orderList[j]
        if (order.shopId === shopId) {
          if (checkFlag) {
            break
          }
          if (!order.couponsUserId || order.couponsUserId < 1) {
            for (var i = couponsList.length - 1; i >= 0; i--) {
              if (checkFlag) {
                break
              }
              var oneCoupons = couponsList[i]
              if (oneCoupons.shopId === order.shopId) {
                for (var k = oneCoupons.list.length - 1; k >= 0; k--) {
                  if (checkFlag) {
                    break
                  }
                  var coupons = oneCoupons.list[k]
                  if (coupons.status !== 1) {
                    continue
                  }
                  var hyFlag = false
                  for (var n = 0; n < checkCouponsUserIds.length; n++) {
                    if (checkFlag) {
                      break
                    }
                    if (checkCouponsUserIds[n] === coupons.couponsUserId) {
                      hyFlag = true
                      break
                    }
                  }
                  if (!hyFlag) {
                    order.couponsTitle = '还差' + parseFloat(coupons.orderAmount - parseFloat(order.allAmount - order.tcGoodsMoneyCount - order.depositGoodsAmount - parseFloat(order.sendPrice || 0).toFixed(2))).toFixed(2) + '元，可使用' + coupons.title
                    order.couponsTS = true
                    checkFlag = true
                    break
                  }
                }
              }
            }
          }
        }
      }
      this.shopOrder = orderList
    },
    // 跳转到优惠活动页面
    async todiscountCoupon (shopId, idx) {
      var order = this.shopOrder[idx]
      var url = 'v1/coupons/couponsList.json'
      var param = {
        userId: localStorage.userId,
        shopId: order.shopId,
        orderAmount: order.orderAmount,
        sendPrice: order.shippingFee
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      res.data.list.forEach(item => {
        item.useFlag = 0 // 不可用
      })
      var checkOrder = this.shopOrder[idx]
      checkOrder.couponsList = res.data.list
      var couponsList = this.couponsList
      var couponsAmount = 0// 优惠券金额
      // var couponsList3 = []
      var goodsList = checkOrder.goodsList
      for (var k = 0; k < checkOrder.couponsList.length; k++) {
        var coupons = checkOrder.couponsList[k]
        if (coupons.status === 2) { // 商品优惠券
          var goodsIds = coupons.goodsIds.split(',')
          var couponsGoodsAmount = 0
          var shoppingVoucherAmount = 0
          for (var m = 0; m < goodsIds.length; m++) {
            var gId = goodsIds[m]
            for (var g = 0; g < goodsList.length; g++) {
              if (goodsList[g].goodsId === gId) {
                couponsGoodsAmount += goodsList[g].shopPrice * goodsList[g].goodsNum
                if (goodsList[g].shoppingVoucherFlag === '1') {
                  shoppingVoucherAmount += goodsList[g].goodsNum * (goodsList[g].shoppingVoucherAmount || 0)
                }
              }
            }
          }
          // 商品总金额 减去 购物券金额
          if (this.data.orderShoppingVoucherFlag === '1') {
            couponsGoodsAmount = couponsGoodsAmount - shoppingVoucherAmount
          }
          // 商品总金额 大于 优惠券最小订单金额
          if (parseFloat(parseFloat(couponsGoodsAmount).toFixed(2) - parseFloat(coupons.orderAmount).toFixed(2)) >= 0) {
            // 优惠券金额 大于 上一个可用优惠券金额
            if (parseFloat(parseFloat(coupons.amount).toFixed(2) - parseFloat(couponsAmount).toFixed(2)) > 0) {
              coupons.useFlag = 1
            }
          }
        }
        if (coupons.status === '1') {
          // 店铺优惠券 无条件使用 有条件使用
          if ((coupons.usedCondition === '0' && parseFloat(parseFloat(order.allAmount - order.tcGoodsMoneyCount - order.depositGoodsAmount).toFixed(2) - parseFloat(order.sendPrice || 0).toFixed(2) - parseFloat(coupons.orderAmount).toFixed(2)) >= 0) ||
            (coupons.usedCondition === '1' && parseFloat(parseFloat(order.allAmount - order.tcGoodsMoneyCount - order.depositGoodsAmount).toFixed(2) - parseFloat(order.sendPrice || 0).toFixed(2) - parseFloat(coupons.amount).toFixed(2)) >= 0)) {
            // 优惠券金额 大于 上一个可用优惠券金额
            if (parseFloat(parseFloat(coupons.amount).toFixed(2) - parseFloat(couponsAmount).toFixed(2)) > 0) {
              coupons.useFlag = 1
            }
          }
        }
      }
      // for (var m = 0; m < checkOrder.couponsList.length; m++) {
      //   var coupons = checkOrder.couponsList[m]
      //   for (var n = 0; n < checkCouponsUserIds.length; n++) {
      //     if (checkCouponsUserIds[n] === coupons.couponsUserId) {
      //       coupons.showFlag = 0
      //       break
      //     }
      //   }
      //   coupons.useFlag = 0
      //   if ((coupons.usedCondition === '0' && parseFloat(parseFloat(checkOrder.allAmount - checkOrder.tcGoodsMoneyCount - checkOrder.depositGoodsAmount).toFixed(2) - parseFloat(checkOrder.sendPrice || 0).toFixed(2) - parseFloat(coupons.orderAmount).toFixed(2)) >= 0) ||
      //     (coupons.usedCondition === '1' && parseFloat(parseFloat(checkOrder.allAmount - checkOrder.tcGoodsMoneyCount - checkOrder.depositGoodsAmount).toFixed(2) - parseFloat(checkOrder.sendPrice || 0).toFixed(2) - parseFloat(coupons.amount).toFixed(2)) >= 0)) {
      //     coupons.useFlag = 1
      //   }
      //   if (coupons.status === 2) { // 商品优惠券
      //     var goodsIds = coupons.goodsIds.split(',')
      //     var couponsGoodsAmount = 0
      //     for (var l = 0; l < goodsIds.length; l++) {
      //       var gId = goodsIds[l]
      //       for (var g = 0; g < checkOrder.goodsList.length; g++) {
      //         if (checkOrder.goodsList[g].goodsId === gId) {
      //           couponsGoodsAmount += checkOrder.goodsList[g].shopPrice * checkOrder.goodsList[g].goodsNum
      //         }
      //       }
      //     }
      //     if (parseFloat(parseFloat(couponsGoodsAmount).toFixed(2) - parseFloat(coupons.orderAmount).toFixed(2)) >= 0) {
      //       coupons.useFlag = 1
      //     } else {
      //       coupons.useFlag = 0
      //     }
      //   }
      //   couponsList3.push(coupons)
      // }
      // checkOrder.couponsList = couponsList3
      var oneCoupons1 = {}
      for (var z = 0; z < couponsList.length; z++) {
        if (shopId === couponsList[z].shopId) {
          oneCoupons1 = couponsList[z]
          break
        }
      }
      oneCoupons1.list = checkOrder.couponsList
      var list = []
      list.push(oneCoupons1)
      localStorage.setItem('couponsList', JSON.stringify(list))
      // 跳转到优惠活动页面
      this.$router.push({
        name: 'DiscountCoupon',
        params: {
          shopId: shopId
        }
      })
    },
    // 获取运费
    async getShopsPrice () {
      const url = 'v1/userShop/getShopsPrice.json'
      const param = {
        shopIds: config.shopId,
        customerUserId: localStorage.userId || ''
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.sendPrice = res.data.list[0].sendPrice
      // 计算获取数据
      this.getAllAmount()
      if (localStorage.isUpdateMessage === 'true') {
        this.message = localStorage.message
        const messageModel = JSON.parse(localStorage.getItem('messageModel'))
        this.shoplist.forEach((item, index) => {
          if (item.shopId === messageModel.shopId) {
            item.message = localStorage.message
          }
        })
        localStorage.setItem('messageModel', JSON.stringify({}))
        localStorage.isUpdateMessage = false
      }
    },
    selectMessageClick (model) {
      // localStorage.messageShopId = model.shopId
      localStorage.setItem('messageModel', JSON.stringify(model))
      this.$router.push({
        name: 'Feedback',
        params: {
          feedType: 1
        }
      })
    },
    // 提交订单
    async confirmOrder () {
      let sendAllPrice = 0
      let addressId = ''
      let dispatchType = ''
      var confirmShopOrder = []
      if (this.currentTab === 0) {
        addressId = this.addressModel.id
        dispatchType = 1
        if (!addressId) {
          this.$toast('请先选择收货地址！')
          return
        }
      }
      if (this.currentTab === 1) {
        addressId = this.pickUpModel.id
        dispatchType = 0
        if (!addressId) {
          this.$toast('请先选择自提点！')
          return
        }
      }
      this.shoplist.forEach((item, index) => {
        var shopParam = Object()
        shopParam.goodsList = []
        shopParam.orderAmount = item.orderAmount
        shopParam.shopAmount = item.shopAmount
        shopParam.goodsAmount = item.goodsAmount
        shopParam.shippingFee = this.sendPrice
        sendAllPrice += shopParam.shippingFee
        shopParam.sendTimeFlag = item.sendTimeFlag
        shopParam.depositAmount = item.depositAmount
        shopParam.shoppingVoucherAmount = item.shoppingVoucherAmount
        shopParam.dispatchType = dispatchType
        shopParam.shopId = item.shopId
        shopParam.addressId = addressId
        shopParam.message = item.message
        // 优惠活动的id 满减id 优惠券id 优惠券减的金额
        shopParam.fullCatId = item.fullCatId || ''
        shopParam.couponsUserId = item.couponsUserId || ''
        shopParam.fullCatMoney = item.fullCatMoney || ''
        item.goodsList.forEach((itemSub, indexSub) => {
          var goodParam = Object()
          goodParam.goodsId = itemSub.goodsId
          goodParam.cartId = itemSub.id
          goodParam.goodsNumber = itemSub.goodsNum
          shopParam.goodsList.push(goodParam)
        })
        confirmShopOrder.push(shopParam)
      })
      const param = {
        userId: localStorage.userId,
        isJson: 1,
        addressId: addressId,
        customerType: 0,
        payType: 9,
        terminalType: 3, // 终端来源(0:Andiod,1:Android-Pad,2:IOS,3:H5,4小程序)
        shopOrder: confirmShopOrder
      }
      ajaxParam(param)
      const res = await post('v1/orderInfo/saveClientOrder.json', param)
      var orderPaySn = res.data.orderPaySn
      var orderId = res.data.orderId || ''
      if (res.data.code === '200') {
        router.push({
          path: '/paytype',
          query: {
            paySn: orderPaySn,
            orderId: orderId,
            isGroup: 0,
            snType: 1,
            allAmount: this.allAmount,
            sendAllPrice: sendAllPrice,
            addressId: addressId
          }
        })
      } else {
        this.$toast(res.data.msg)
      }
    }
  },
  created () {
    this.shoplist = JSON.parse(localStorage.getItem('payCarList'))
    // this.addressModel = localStorage.addressModel ? JSON.parse(localStorage.addressModel) : {}
    this.addressModel = this.$store.state.addressModel
    if (this.addressModel.empty || !this.addressModel.id) {
      this.getDefaultAddress()
    }
    this.pickUpModel = localStorage.pickUpModel ? JSON.parse(localStorage.pickUpModel) : {}
    // 从缓存中读取选择的优惠券
    this.couponsList = JSON.parse(localStorage.getItem('checkedCouponItem')) || []
    // 选择的优惠券id
    // this.checkCouponsUserIds = JSON.parse(localStorage.getItem('checkCouponsUserIds')) || []
    this.getShopsPrice()
  },
  mounted () {
    const params = this.$route.params.type
    if (params === 'picksite') {
      this.currentTab = 1
    }
  },
  unmounted () {
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
.edit-btn-wrap{
    background:$green-font;
    padding: .15rem .1rem .3rem;
    display: flex;
    justify-content: flex-end;
    color:#fff
}
.confrim-order{margin: .1rem; padding-bottom: .3rem;}
.fecth-nav {
  height: 0.4rem;
  border-radius: 10px 10px 0 0;
  background: #fff;
  line-height: 0.4rem;
  font-size: 0.16rem;
  position: relative;
  .fecth-nav-item {
    width: 50%;
    height: 0.4rem;
    line-height: 0.4rem;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 2;
    }
    .fecth-nav-item span {
    position: absolute;
    width: 100%;
    height: 0.4rem;
    text-align: center;
    }
    .fecth-nav-item img {
    width: 100%;
    height: 0.4rem;
    }
    .fecth-nav-item:nth-of-type(1) {
    left: 0;
    border-radius: 10px 0 0 10px;
    }
    .fecth-nav-item:nth-of-type(1) img {
    border-radius: 10px 0 0 10px;
    }
    .fecth-nav-item:nth-of-type(1) span {
    left: 0;
    }
    .fecth-nav-item:nth-of-type(2) {
    right: 0;
    border-radius: 0 10px 0 0;
    }
    .fecth-nav-item:nth-of-type(2) img {
    border-radius: 0 10px 0 0;
    }
    .fecth-nav-item:nth-of-type(2) span {
    right: 0;
    }
    .fecth-nav-item.active {
    background: #fff;
    color: $green-font
    }
    .fecth-nav-item.active img {
    display: none;
    }
    .fecth-nav-item:nth-of-type(2).active {
    z-index: 1;
    }
}
.address-wrap {
  background: #fff;
  position: relative;
  .address-icon-l {
        display: block;
        width: 0.24rem;
        height: 0.24rem;
        position: absolute;
        left: 0.15rem;
        top: 50%;
        margin-top: -0.12rem;
    }
    .address-icon-r {
        display: block;
        width: 0.08rem;
        height: 0.1rem;
        position: absolute;
        right: 0.15rem;
        top: 50%;
        margin-top: -0.05rem;
    }
    .deliver-address {
        padding: .1rem 0.3rem .1rem 0.6rem;
    }
    .deliver-address p {
        line-height: 0.3rem;
    }
}
.orderlist{
    background:#fff;
    margin: .1rem 0;
    padding: .1rem .15rem;
    .order-shop{
        display: flex;
        justify-content: space-between;
        padding-bottom: .1rem;
        border-bottom: 1px solid #f3f3f3;
        .order-shop-l{
            display: flex;
            align-items: center;
            .shop-icon{
                width: .19rem;
                height: .19rem;
            }
            span{
                margin: 0 .08rem;
                font-size: .13rem;
            }
            .shop-arrow{
                width:.09rem;
                height: .1rem;
            }
        }
    }
    .goodslist{
        width: 100%;
        padding: .1rem 0;
        box-sizing: border-box;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        align-items: center;
        img{
            width:.7rem;
            height: .7rem;
            margin-right:.1rem;
        }
        .goods-info{
            flex:1;
            position: relative;
            height:.62rem;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            .goods-name{
                font-weight: bold;
            }
            .goods-spec{
                color:#666; font-size: .13rem;
            }
            .goods-price{
                color:$green-font
            }
        }
    }
    .detail-cell{
        display: flex;
        justify-content: space-between;
        padding: .1rem 0;
        border-bottom: 1px solid #f3f3f3;
        color:#666;
        font-size: .13rem;
        span{
            color:#999
        }
    }
    .detail-coupon-cell{
        label{
            flex:1
        }
        img{
            width: .07rem;
            height: .1rem;
            margin-left: .05rem
        }
    }
    .shop-amount{
        justify-content: flex-end;
        span{
            padding: 0 .05rem;
            color:$red-font;
        }
    }
}
.fix-bottom{
    position: fixed;
    left:0; right:0; bottom:0;
    height: .4rem;
    background:#fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fix-bottom-l{
        display: flex;
        padding: 0 .1rem;
        align-items: center;
        .select-all-wrap{
          display: flex;
          align-items: center;
        }
        img{
            width: .16rem;
            height: .16rem;
            margin-right: .05rem
        }
        span{font-size: .12rem; color:$red-font;}
        .select-all{
          font-size: .14rem;
          margin-right:.1rem;
        }
    }
    .fix-bottom-r{
        height:100%;
        background:#39A94A;
        padding: 0 .3rem;
        color:#fff;
        line-height: .4rem;
    }
    .disabled{
        background:gray
    }
    .delete-btn{
        background: #ff371e;
        color:#fff
    }
}
</style>
