<template>
    <div class="coupons-list" v-if="couponItem.useFlag==1" @click="checkedCoupons(couponItem)">
        <div class="coupons-list-l">
            <div class="coupons-amount">￥<span>{{couponItem.amount}}</span></div>
            <div class="coupons-use" v-if="couponItem.usedCondition==0">满{{couponItem.orderAmount}}元减</div>
        </div>
        <div class="coupons-list-info">
            <h4>{{couponItem.title}}</h4>
            <div class="coupons-tags">
                <span v-if='couponItem.usedCondition==0'>{{couponItem.status==1?'店铺':'商品'}}满减券({{couponItem.status==1?'套餐商品除外':'部分商品可用'}})</span>
                <span v-if="couponItem.usedCondition==1">优惠券(套餐商品除外)</span>
            </div>
            <div class="coupons-time">
                <text class="iconfont icon-shijian"></text>
                <span>{{couponItem.useBeginTime}}</span>
                <span class="space">至</span>
                <span>{{couponItem.useEndTime}}</span>
            </div>
        </div>
        <div class="coupons-list-r">
            <span class="coupons-day">仅剩{{couponItem.dayNum}}天</span>
         </div>
    </div>
    <div class="coupons-list fcbgray" v-if="couponItem.useFlag==0" >
        <div class="coupons-list-l">
            <div class="coupons-amount">￥<span>{{couponItem.amount}}</span></div>
            <div class="coupons-use" v-if="couponItem.usedCondition==0">满{{couponItem.orderAmount}}元减</div>
        </div>
        <div class="coupons-list-info">
            <h4>{{couponItem.title}}</h4>
            <div class="coupons-tags">
                <span v-if='couponItem.usedCondition==0'>{{couponItem.status==1?'店铺':'商品'}}满减券({{couponItem.status==1?'套餐商品除外':'部分商品可用'}})</span>
                <span v-if="couponItem.usedCondition==1">优惠券(套餐商品除外)</span>
            </div>
            <div class="coupons-time">
                <text class="iconfont icon-shijian"></text>
                <span>{{couponItem.useBeginTime}}</span>
                <span class="space">至</span>
                <span>{{couponItem.useEndTime}}</span>
            </div>
        </div>
        <div class="coupons-list-r">
            <span class="coupons-day">仅剩{{couponItem.dayNum}}天</span>
         </div>
    </div>
</template>
<script>

export default {
  props: ['couponItem'],
  data () {
    return {
      shopId: this.$route.params.shopId || ''
    // usedCondition  0 满减券  1无条件使用
    }
  },
  methods: {
    // 选中优惠券
    async checkedCoupons (couponItem) {
      var checkedCouponItem = JSON.parse(localStorage.getItem('checkedCouponItem')) || []
      var couponsList = JSON.parse(localStorage.getItem('couponsList')) || []
      for (var j = 0; j < couponsList.length; j++) {
        var coupons = couponsList[j]
        if (coupons.shopId.toString() === this.shopId) {
          // 查询选中的优惠券中是否有该优惠券
          var flag = true
          for (var k = 0; k < checkedCouponItem.length; k++) {
            if (checkedCouponItem[k].shopId.toString() === this.shopId) {
              checkedCouponItem[k].list = []
              checkedCouponItem[k].list.push(couponItem)
              flag = false
            }
          }
          if (flag === true) {
            coupons.list = []
            coupons.list.push(couponItem)
            checkedCouponItem.push(coupons)
          }
          localStorage.setItem('checkedCouponItem', JSON.stringify(checkedCouponItem))
        }
      }
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
.coupons-list{
    background:#fff;
    margin: .1rem .1rem 0;
    border-radius: .05rem;
    display: flex;
    align-items: center;
    height: .9rem;
    font-size: .12rem;
    .coupons-list-l{
        width:.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color:#f46c18;
        .coupons-amount{
            span{
                font-weight: bold;
                font-size: .16rem;
            }
        }
        .coupons-use{
            margin-top: .1rem
        }
    }
    .coupons-list-info{
        flex:1;
        display: flex;
        flex-direction: column;
        border-left: 1px dashed #ddd;
        padding-left: .1rem;
        h4{
            font-size: .14rem;
        }
        .coupons-tags{
            display: flex;
            padding: .1rem 0;
            span{
                margin-right: .05rem;
                padding: .01rem .07rem;
                border-radius: .1rem;
                background:rgba(244,108,24,.1);
                color:#f46c18;
                font-size: .12rem;
            }
        }
        .coupons-time{
            display: flex;
            align-items: center;
            color:#999;
            font-size: .12rem;
            .iconfont{
                margin-right: .05rem
            }
            .space{
                padding: 0 .03rem;
            }
        }
    }
    .coupons-list-r{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        height: .62rem;
        margin-right: .1rem;
        color:#f46c18;
        .coupons-btn{
            width:.45rem;
            height: .22rem;
            background:#f46c18;
            border-radius: .2rem;
            text-align: center;
            line-height: .22rem;
            color:#fff
        }
        .coupons-btn-disabled{
            background:#ccc;
        }
    }
}
.fcbgray{
    color: #bbbbbb;
    .coupons-list-l{
        color:#bbb
    }
    .coupons-list-info{
        .coupons-tags span{
            color:#bbb
        }
    }
    .coupons-list-r{
        color: #bbb;
    }
}
</style>
