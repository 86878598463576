<template>
    <div class="container">
        <DiscountCoupons v-for="item in couponsList" :key="item.id" :couponItem="item"/>
    </div>
</template>
<script>
// import qs from 'qs'
// import { post, ajaxParam, config } from '../../units/request.js'
import DiscountCoupons from '../../components/DiscountCoupons.vue'
import { formatTimeDate } from '../../units/request'
// import ContentNo from '../../components/ContentNo.vue'
export default {
  data () {
    return {
      couponsList: []
    }
  },
  name: 'DiscountCoupon',
  components: {
    DiscountCoupons
  },
  methods: {
  },
  created () {
    var shopId = this.$route.params.shopId || ''
    // 从缓存中获取优惠券
    var couponsList = JSON.parse(localStorage.getItem('couponsList')) || []
    for (var j = 0; j < couponsList.length; j++) {
      var coupons = couponsList[j]
      if (coupons.shopId.toString() === shopId) {
        this.couponsList = coupons.list
      }
    }
    this.couponsList.forEach(item => {
      const myDateBegin = new Date(item.useBeginTime)
      const myDateEnd = new Date(item.useEndTime)
      item.useBeginTime = formatTimeDate(myDateBegin)
      item.useEndTime = formatTimeDate(myDateEnd)
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
