<template>
    <div class="container">
        <a class="mylist" :href="aboutUrl">
            <div class="mylist-l">
                <img src="../../images/setup_01.png"/>
                <span>关于我们</span>
            </div>
            <img class="mylist-r" src="../../images/right_arrow.png"/>
        </a>
        <div class="mylist" @click="$router.push('/password')">
            <div class="mylist-l">
                <img src="../../images/setup_02.png"/>
                <span>修改密码</span>
            </div>
            <img class="mylist-r" src="../../images/right_arrow.png"/>
        </div>
        <div class="mylist" @click="$router.push('/feedback')">
            <div class="mylist-l">
                <img src="../../images/setup_04.png"/>
                <span>意见反馈</span>
            </div>
            <img class="mylist-r" src="../../images/right_arrow.png"/>
        </div>
        <a class="mylist" href="tel:15101095160">
            <div class="mylist-l">
                <img src="../../images/setup_03.png"/>
                <span>联系客服</span>
            </div>
            <img class="mylist-r" src="../../images/right_arrow.png"/>
        </a>
        <div class="fixed-bom"  @click="loginout">退出登录</div>
    </div>
</template>
<script>
import { config } from '../../units/request.js'
import { removeCookie } from '../../units/tools.js'
export default {
  data () {
    return {
      aboutUrl: config.baseUrl + 'v1/configApp/about'
    }
  },
  methods: {
    loginout () {
      removeCookie('isLogin')
      removeCookie('userId')
      removeCookie('username')
      removeCookie('openId')
      removeCookie('nickName')
      localStorage.removeItem('cartList')
      localStorage.removeItem('originAddress')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('carNumNew')
      localStorage.removeItem('pickUpModel')
      localStorage.removeItem('addressModel')
      localStorage.removeItem('payCarList')
      // router.replace({ name: 'Login' })
      location.replace(window.location.href.split('#')[0])
    }
  },
  created () {

  }
}
</script>

<style lang="scss" scoped>
.mylist{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:#fff;
    margin-top: .1rem;
    padding:.12rem .15rem;
    .mylist-l{
        display: flex;
        align-items: center;
        img{
            width: .2rem;
            height: .2rem;
            margin-right: .1rem;
        }
    }
    .mylist-r{
        width:.08rem;
        height: .12rem;
    }
}
.login-out{
    display:block;
     width: 3rem;
     height: .4rem;
     margin: .3rem auto;
     background:#2ac560;
     border-radius: .2rem;
     text-align: center;
     line-height: .4rem;
     color:#fff;
     font-size: .15rem;
}
</style>
