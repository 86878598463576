<template>
    <div class="orderlist">
        <div class="order-shop">
            <div class="order-shop-l">
                <img class="shop-icon" src="../images/order_company@2x.png"/>
                <span>{{orderItem.shopName}}</span>
                <img class="shop-arrow" src="../images/right_arrow.png"/>
            </div>
            <div class="order-shop-r">
                <span v-if="orderItem.orderStatus==0">待确认</span>
                <span v-if="orderItem.orderStatus==1">待配货</span>
                <span v-if="orderItem.orderStatus==2">待收货</span>
                <span v-if="orderItem.orderStatus==3">待评价</span>
                <span v-if="orderItem.orderStatus==4">已取消</span>
                <span v-if="orderItem.orderStatus==5">已完成</span>
                <span v-if="orderItem.orderStatus==6">待支付</span>
                <span v-if="orderItem.orderStatus==7">退款中</span>
                <span v-if="orderItem.orderStatus==8">已退款</span>
                <span v-if="orderItem.orderStatus==9">待发货</span>
                <span v-if="orderItem.payStatus==0">(未付款)</span>
                <span v-if="orderItem.payStatus==1">(付款中)</span>
                <span v-if="orderItem.payStatus==2">(已付款)</span>
            </div>
        </div>
        <div class="goodslist" @click="toDetail(orderItem.id)">
            <img v-for="itemsub in orderItem.goodsList" :key="itemsub.id" :src="itemsub?itemsub:require('../images/default_goods.png')"/>
        </div>
        <div class="order-cell order-info">
            共<span>{{orderItem.goodsList.length}}</span>件商品 合计<span class="font-bold">￥{{orderItem.orderAmount}}</span>
            <span class="order-shippingFee" v-if="orderItem.shippingFee>0">(含运费 ￥{{orderItem.shippingFee}})</span>
        </div>
        <div class="order-cell order-time">
            下单时间: {{orderItem.addTime}}
        </div>
        <div class="order-cell order-btn-wrap" v-if="orderItem.orderStatus==0||orderItem.orderStatus==2||orderItem.orderStatus==3||orderItem.orderStatus==4||orderItem.orderStatus==5||orderItem.orderStatus==6||orderItem.orderStatus==8">
            <span class="order-btn" @click="deleteOrderClick(orderItem.id)" v-if="orderItem.orderStatus==3||orderItem.orderStatus==4||orderItem.orderStatus==5||orderItem.orderStatus==8">删除订单</span>
            <span class="order-btn" @click="cancalOrderClick(orderItem.id)" v-if="orderItem.orderStatus==0||orderItem.orderStatus==6">取消订单</span>
            <!-- <span class="order-btn order-green" @click="$router.push({ name:'Refund', params: {orderId: orderItem.id}})" v-if="orderItem.orderStatus==2||orderItem.orderStatus==3">退货</span>
            <span class="order-btn order-green" v-if="orderItem.orderStatus==3">去评价</span> -->
            <span class="order-btn order-green" @click="confirmOrderClick(orderItem.id)" v-if="orderItem.orderStatus==2">确认收货</span>
            <span class="order-btn order-green" v-if="orderItem.orderStatus==6" @click="$router.push({ path:'/paytype', query: {paySn:orderItem.orderSn,isGroup: 0,allAmount: orderItem.orderAmount}})">付款</span>
        </div>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../units/request.js'
export default {
  props: ['orderItem'],
  methods: {
    toDetail (id) {
      this.$router.push({
        path: '/orderDetail',
        query: {
          orderId: id
        }
      })
    },
    // 取消订单
    cancalOrderClick (id) {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '是否取消当前订单？'
      }).then(() => {
        // confirm 取消订单
        this.changeOrderStatus(id, 4)
      })
    },
    // 确认收货
    confirmOrderClick (id) {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '是否确认收货？'
      }).then(() => {
        // confirm 确认收货
        this.changeOrderStatus(id, 3)
      })
    },
    // 删除订单
    deleteOrderClick (id) {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '是否删除当前订单？'
      }).then(async () => {
        // confirm 删除订单
        const url = 'v1/orderInfo/client/deleteOrder.json'
        const param = {
          userId: localStorage.userId,
          orderId: id
        }
        ajaxParam(param)
        const res = await post(url, qs.stringify(param))
        console.log(res)
        this.$emit('updateOrder')
      })
    },
    // 改变订单状态
    async changeOrderStatus (orderId, status) {
      const url = 'v1/orderInfo/client/editStatus.json'
      const param = {
        userId: localStorage.userId,
        orderStatus: status,
        orderId: orderId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      console.log(res)
      this.$emit('updateOrder')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style/viriables.scss';
.orderlist{
    background:#fff;
    margin: .1rem .1rem 0;
    border-radius: .1rem;
    padding: .1rem;
    .order-shop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: .1rem;
        border-bottom: 1px solid #f3f3f3;
        .order-shop-l{
            display: flex;
            align-items: center;
            .shop-icon{
                width: .19rem;
                height: .19rem;
            }
            span{
                margin: 0 .08rem;
                font-size: .13rem;
            }
            .shop-arrow{
                width:.09rem;
                height: .1rem;
            }
        }
        .order-shop-r{
            color:$red-font;
            font-size: .13rem;
            transform: scale(.8);
        }
    }
    .goodslist{
        width: 100%;
        padding: .1rem 0;
        box-sizing: border-box;
        overflow: auto;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        img{
            width:.7rem;
            height: .7rem;
            margin-right:.1rem;
        }
    }
    .order-cell{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: .08rem 0;
        border-bottom: 1px solid #f3f3f3;
        color:#666;
    }
    .order-info{
        font-size: .12rem;
        span{
            padding: .01rem;
            color: $red-font;
        }
        .font-bold{
            font-weight: bold;
        }
        .order-shippingFee{
            margin-left: -.03rem;
            padding-left: 0;
            font-size: .13rem;
            color:$gray999-font;
            transform: scale(.8);
            transform-origin: center top;
        }
    }
    .order-time{
        font-size: .12rem;
    }
    .order-btn-wrap{
        border-bottom: none;
        padding-bottom: 0;
    }
    .order-btn{
        margin-left: .15rem;
        padding:.06rem .1rem;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        font-size: .12rem;
    }
    .order-green{
        background:#2ac560;
        border-color: #2ac560;
        color:#fff
    }
}
</style>
